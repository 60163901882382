<div id="encabezado" class="navbar fixed-top">

    <div class="nav justify-content-left" id="encabezadoIzq">
        <!-- <a routerLink="/login" style="margin:0; padding:0"><img src="assets/logo.jpg" style="height: 50px; padding-right: 15px;"></a> -->
        <!-- <a routerLink="/login" style="margin:0; padding:0"><img src="assets/logo.jpg" style="height: 80px; padding-right: 15px;"></a> -->
        <a routerLink="/login" style="margin-top:-10px; padding:0"><img src="assets/swift-logo.jpg" style="height: 100px; padding-right: 15px;"></a>
        <h1>Communications channel</h1>
    </div>

</div>
<!-- <div style="height: 75px; margin-top:-5px"></div> -->
<div style="height: 100px; margin-top:-5px"></div>



<router-outlet></router-outlet>




<footer class="footerClass">
    <div id="google_translate_element" class="traductor nav justify-content-end" style="float:right"></div>

    <div class="text-left" style="float:left">
        Copyright ©2023 Mithra Legal Advisors. <br>
        <span class="aparte">Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of <br> the European Union or the European Education and Culture
        Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.</span>
    </div> <br id="brmobile">

    <a class="text-right" style="float: right;" routerLink="/proteccion-datos">DATA PROTECTION POLICY AND CONSENT</a> <br id="brmobile">
    <!-- <a class="text-right" style="float: right; margin-right: 20px;" routerLink="/terminos-uso">Terminos de uso</a> <br id="brmobile">
    <a class="text-right" style="float: right; margin-right: 20px;" routerLink="/canales-externos">Canales de denuncia externos</a> -->

</footer>